import axios from "axios";
import config from "../config";

axios.interceptors.request.use(
  (requestConfig) => {
    if (config.token) {
      requestConfig.headers["Authorization"] = "Bearer " + config.token;
    }
    return requestConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);
