import React from "react";
import "../404-Error-Page/errorpage.css";
function Errorpage() {
  return (
    <div className="main">
      <h1 className="error">404 Error</h1>
      <h3 className="exist">The Page You're looking for doesn't exist</h3>
      <u>
        <a href="/dashboard" className="home-button">
          Go To Home
        </a>
      </u>
    </div>
  );
}

export default Errorpage;
