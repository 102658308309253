import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import ToastSnackbar from "../snack-bar/Alertmsg";
import config from "../../config";
import * as url from "../assets/helpers/url_helper";
import Otppage from "../OTP Page/Otp";
import { Grid, TextField, Typography } from "@mui/material";
// import { Container, TextField, Button, Typography, Box } from "@mui/material";
// import Sample from "../image/Left.jpg";
import loginlogo from "../image/vy-logo.png";
import Lock from "../image/login-icon.png";
import "../styles/login.css";
import Footer from "./Footer.js";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import LoginAnimation from "../../Component/assets/Json/login-animation.json";
function Login(props) {
  const {
    otppopup,
    setotppopup,
    setemail,
    email,
    openToast,
    setOpenToast,
    severityNote,
    setSeverityNote,
    setmessages,
    messages,
    snackBarTimeOut,
    isLoading,
    setIsLoading,
  } = props || {};
  const snackbarprops = {
    openToast,
    severityNote,
    messages,
    setOpenToast,
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email address"
      ),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setemail(formik.values.email);
      try {
        const response = await axios.post(
          config.apiEndpoint + url.OTP_GENERATE,
          {
            userEmail: values.email,
          }
        );
        console.log(response);
        setotppopup(true);
        setOpenToast(true);
        snackBarTimeOut();
        setSeverityNote("success");
        setIsLoading(false);
        setmessages(response.data.message);
      } catch (error) {
        setotppopup(false);
        setOpenToast(true);
        setSeverityNote("error");
        snackBarTimeOut();
        setIsLoading(false);
        setmessages(error.response.data.message);
      }
    },
  });
  const diologprops = {
    otppopup,
    email,
    openToast,
    setOpenToast,
    severityNote,
    setSeverityNote,
    setmessages,
    messages,
    snackBarTimeOut,
    isLoading,
    setIsLoading,
  };
  return (
    <Grid container className="login-container">
      <Grid item xs={false} sm={4} md={7} className="image">
        {/* <img src={RightBanner} alt="logo" className="right-banner" /> */}
        <div className="login-animation">
          <Player
            autoplay
            loop
            src={LoginAnimation}
            style={{ height: "100vh", width: "850px" }}
            className="logout-animation"
          >
            <Controls
              visible={false}
              buttons={["play", "repeat", "frame", "debug"]}
            />
          </Player>
        </div>
      </Grid>

      <Grid item xs={12} sm={8} md={5} className="rightside">
        {otppopup ? (
          <Otppage {...diologprops} />
        ) : (
          <div className="paper">
            <form className="form" onSubmit={formik.handleSubmit}>
              <div className="logo-container">
                {" "}
                <img src={loginlogo} alt="logo" className="loginlogo" />
              </div>

              <div className="vylogo">
                <img src={Lock} alt="logo" />
              </div>
              <Typography className="loginheading" component="h1" variant="h5">
                Login Your Account
              </Typography>

              <div className="login_box">
                <label htmlFor="email">Email</label>
                <TextField
                  style={{ marginTop: "10px" }}
                  fullWidth
                  autoComplete="email"
                  className="login-email"
                  id="email"
                  type="email"
                  onChange={(event) => {
                    const lowercaseEmail = event.target.value.toLowerCase();
                    formik.handleChange(event);
                    formik.setFieldValue("email", lowercaseEmail);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  name="email"
                  placeholder="example@gmail.com"
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="errormsg">{formik.errors.email}</div>
                ) : null}
              </div>

              <button type="submit" className="submit">
                Login
              </button>

              <Footer />
            </form>
            {/* <Footer/> */}
          </div>
        )}
        <ToastSnackbar {...snackbarprops} />
      </Grid>
    </Grid>
  );
}
export default Login;
