import React from 'react'
import "../styles/footerfile.css"
const Footerfile = () => {
  return (
    <div className="footersection">
    <footer className="footermain">
      <p>
      &copy; {new Date().getFullYear()} vysystems.com 
      </p>
    </footer>
    </div>
  )
}

export default Footerfile
