import React from "react";
import "../styles/FileList.css";
import { Grid, TextField, InputAdornment } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import * as url from "../assets/helpers/url_helper";
import config from "../../config";
// import DownloadIcon from "../image/cloud-download.svg";
import ReactPaginate from "react-paginate";
import SearchIcon from "@mui/icons-material/Search";
import ToastSnackbar from "../snack-bar/Alertmsg";
import Header from "../header/Header";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CanticateDetails from "../Candicate-Details-Form/Canticate";
import Loader from "../Loader/Loader";
import ExpiryScreen from "../Expiry-Login-Screen/ExpiryScreen";
import downloadFileFromS3 from "../Aws-File/AwsConfig";
import Trashman from "../image/trash-man.png";
// import NoFile from "../image/download file error.svg";
import Footerfile from "./Footerfile";
function FileList(props) {
  const {
    openToast,
    setOpenToast,
    severityNote,
    setSeverityNote,
    setmessages,
    messages,
    snackBarTimeOut,
    Edit,
    setEdit,
    EditData,
    setEditData,
    CanId,
    SetCanId,
    isLoading,
    setIsLoading,
    TokenExpired,
    setTokenExpired,
  } = props || {};
  const snackbarprops = {
    openToast,
    severityNote,
    messages,
    setOpenToast,
  };
  const EditDataprops = {
    Edit,
    setEdit,
    EditData,
    setEditData,
    CanId,
    SetCanId,
    setOpenToast,
    setSeverityNote,
    setmessages,
    snackBarTimeOut,
  };

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [selectedButton, setSelectedButton] = React.useState("");
  const [nameFilter, setNameFilter] = React.useState("");
  const [emailFilter, setEmailFilter] = React.useState("");
  const [countryFilter, setCountryFilter] = React.useState("");
  const [data, setData] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState(data);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [DataError, setDataError] = React.useState(false);
  const [FileData, setFileData] = React.useState();
  const [FileExpiry, setFileExpiry] = React.useState();
  const [DeletePopupopen, setDeletePopupopen] = React.useState(false);
  const [deleteCandidateId, setDeleteCandidateId] = React.useState(null);

  const itemsPerPage = 20;
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentPageData = filteredData.slice(offset, offset + itemsPerPage);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    return `${formattedDate}, `;
  }
  console.log(scroll, FileExpiry);
  //fetch datas
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          config.apiEndpoint + url.CANDIDATE_TABLE_LIST
        );
        const fetchedData = response.data.data;
        setData(fetchedData);
        setFilteredData(fetchedData);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === "Token expired"
        ) {
          setTokenExpired(true);
        }
      }
    };

    fetchData();
  }, [nameFilter, emailFilter, countryFilter, setIsLoading, setTokenExpired]);

  React.useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  if (isLoading) {
    return <Loader />;
  }
  const getCountryName = (countryCode) => {
    switch (countryCode) {
      case 1:
        return <p>USA</p>;
      case 10:
        return <p>Canada</p>;

      default:
        return "";
    }
  };
  //get filter
  const handleFilter = async () => {
    try {
      const response = await axios.get(
        config.apiEndpoint + url.CANDIDATE_FILTER_VALUES,
        {
          params: {
            candidateName: nameFilter,
            candidateEmail: emailFilter,
            countryCode: countryFilter,
          },
        }
      );
      const filteredData = response.data.data;
      setFilteredData(filteredData);
      setCurrentPage(0);
    } catch (error) {
      setDataError(error.message);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Token expired"
      ) {
        setTokenExpired(true);
      }
    }
  };
  //clear filter
  const handleClearFilter = async () => {
    setNameFilter("");
    setEmailFilter("");
    setCountryFilter("");
    try {
      const response = await axios.get(
        config.apiEndpoint + url.CANDIDATE_FILTER_VALUES
      );
      const fetchedData = response.data.data;
      setData(fetchedData);
      setFilteredData(fetchedData);
      setCurrentPage(0);
      if (fetchedData.length > 0) {
        setDataError("");
      }
    } catch (error) {
      console.error(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Token expired"
      ) {
        setTokenExpired(true);
      }
    }
  };

  //popup data
  const FileDetailsPopup = async (scrollType, buttonType, Canid, Expiry) => {
    setSelectedButton(buttonType);
    setScroll(scrollType);
    setFileExpiry(Expiry);

    try {
      const response = await axios.get(
        config.apiEndpoint + url.CANDIDATE_FILE_DETAILS,
        { params: { can_id: Canid, fileType: buttonType } }
      );
      const fetchedData = response.data.data;
      console.log(fetchedData);
      setFileData(fetchedData);

      setOpen(true);
    } catch (error) {
      console.error(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Token expired"
      ) {
        setTokenExpired(true);
      }
    }
  };
  //Delete Details
  const handleDelete = async (can_id) => {
    try {
      await axios.delete(config.apiEndpoint + url.CANDIDATE_DATA_DELETE, {
        params: { can_id: can_id },
      });

      setSeverityNote("success");
      setmessages("Data deleted successfully");
      setOpenToast(true);
      snackBarTimeOut();
    } catch (error) {
      console.error(error);
      setSeverityNote("error");
      setmessages("Failed to delete data");
      setOpenToast(true);
      snackBarTimeOut();
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Token expired"
      ) {
        setTokenExpired(true);
      }
    }
  };
  const handleDeletePopupOpen = (can_id) => {
    setDeletePopupopen(true);
    setDeleteCandidateId(can_id);
  };

  const handleDeletePopupClose = () => {
    setDeletePopupopen(false);
    setDeleteCandidateId(null);
  };
  //Edit Details
  const EditDetails = async (Canid) => {
    try {
      const response = await axios.get(
        config.apiEndpoint + url.CANDIDATE_DATA_EDIT,
        { params: { can_id: Canid } }
      );
      const fetchedData = response.data.data;
      console.log(fetchedData);
      setEdit(true);
      SetCanId(Canid);
      setEditData(fetchedData);
      setOpen(true);
    } catch (error) {
      console.error(error);
      setEdit(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Token expired"
      ) {
        setTokenExpired(true);
      }
    }
  };
  const handleDownload = async (fileKey) => {
    try {
      if (!fileKey) {
        console.log("File key is missing.");
        return;
      }

      const downloadLink = await downloadFileFromS3([fileKey]);

      if (downloadLink) {
        const anchor = document.createElement("a");
        anchor.href = downloadLink;
        anchor.target = "_blank";
        anchor.style.display = "none";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      } else {
        console.log("Failed to generate download link.");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {TokenExpired ? (
        <ExpiryScreen />
      ) : (
        <>
          {Edit ? (
            <CanticateDetails {...EditDataprops} />
          ) : (
            <div className="file-mainsection">
              <Header />
              <Grid container className="file-content">
                <Grid item xs={12} lg={12} sm={12}>
                  <div className="candidate-heading">
                    <h2>Candidate Details</h2>
                  </div>

                  <div className="filter-container">
                    <div className="filter-item">
                      <label htmlFor="name">Name</label>
                       <TextField
                        id="name"
                        autoComplete="off"
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div className="filter-item">
                      <label htmlFor="email">Email</label>
                      <TextField
                        autoComplete="off"
                        id="email"
                        value={emailFilter}
                        onChange={(e) => setEmailFilter(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div className="filter-item">
                      <label htmlFor="country">Country</label>
                      <select
                        autoComplete="off"
                        className="country-select"
                        id="country"
                        value={countryFilter}
                        onChange={(e) => setCountryFilter(e.target.value)}
                      >
                        <option value="">Select country</option>
                        <option value={1}>USA</option>
                        <option value={10}>Canada</option>
                      </select>
                    </div>
                    </div>
                    <div className="button-filter">

                   
                    <div className="filter-ite">
                      <button onClick={handleFilter} className="filter-button">
                        Filter
                      </button>
                    </div>
                    <div className="filter-ite">
                      <button
                        onClick={handleClearFilter}
                        className="filter-button"
                      >
                        Clear Filter
                      </button>
                    </div>
                    </div>

                  <div className="table-container">
                    <table className="styled-table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Project Start Date</th>
                          <th>Client Name</th>
                          <th>Vendor Name</th>
                          <th>Country</th>
                          <th>H1B</th>
                          <th>H1B Name</th>
                          <th>i9 </th>
                          <th>MSA </th>
                          <th>W9 </th>
                          <th>Visa </th>
                          <th>COI </th>
                          <th>Countersign </th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentPageData.length === 0 || DataError ? (
                          <tr>
                            <td colSpan="14" className="table-err-msg">
                              Data not found
                            </td>
                          </tr>
                        ) : (
                          currentPageData.map((item, index) => (
                            <tr key={index}>
                              <td>{item.can_name}</td>
                              <td>{item.can_email}</td>
                              <td>{formatDate(item.project_start_date)}</td>
                              <td>{item.client_name}</td>
                              <td>{item.vy_vendor_name}</td>
                              <td>{getCountryName(item.country_code)}</td>
                              <td>{item.H1BVendorornot}</td>
                              <td>{item.H1Bvendorname}</td>
                              <td>
                                <button
                                  className="i9-icon"
                                  onClick={() =>
                                    FileDetailsPopup(
                                      "paper",
                                      "i9",
                                      item.can_id,
                                      true
                                    )
                                  }
                                >
                                  I9
                                </button>
                              </td>
                              <td>
                                <button
                                  className="i9-icon"
                                  onClick={() =>
                                    FileDetailsPopup(
                                      "paper",
                                      "msa",
                                      item.can_id,
                                      false
                                    )
                                  }
                                >
                                  MSA
                                </button>
                              </td>
                              <td>
                                <button
                                  className="i9-icon"
                                  onClick={() =>
                                    FileDetailsPopup(
                                      "paper",
                                      "w9",
                                      item.can_id,
                                      false
                                    )
                                  }
                                >
                                  w9
                                </button>
                              </td>
                              <td>
                                <button
                                  className="i9-icon"
                                  onClick={() =>
                                    FileDetailsPopup(
                                      "paper",
                                      "visa",
                                      item.can_id,
                                      false
                                    )
                                  }
                                >
                                  visa
                                </button>
                              </td>
                              <td>
                                <button
                                  className="i9-icon"
                                  onClick={() =>
                                    FileDetailsPopup(
                                      "paper",
                                      "coi",
                                      item.can_id,
                                      true
                                    )
                                  }
                                >
                                  COI
                                </button>
                              </td>
                              <td>
                                <button
                                  className="i9-icon"
                                  onClick={() =>
                                    FileDetailsPopup(
                                      "paper",
                                      "counter_sign",
                                      item.can_id,
                                      false
                                    )
                                  }
                                >
                                  csd
                                </button>
                              </td>
                              <td>
                                <EditIcon
                                  className="edit-icon"
                                  onClick={() => EditDetails(item.can_id)}
                                ></EditIcon>
                                &nbsp; &nbsp;
                                <DeleteIcon
                                  className="delete-icon"
                                  onClick={() =>
                                    handleDeletePopupOpen(item.can_id)
                                  }
                                />
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>

                    <div>
                      {open ? (
                        <div className="table-popup-container">
                          <div className="table-popup-outer">
                            <div className="table-popup-heading">
                              <h2>
                                {" "}
                                {selectedButton
                                  .replace(/_/g, " ")
                                  .charAt(0)
                                  .toUpperCase() +
                                  selectedButton.slice(1).toLowerCase()}{" "}
                                Details
                              </h2>
                              <CancelIcon
                                className="close-icon"
                                onClick={handleClose}
                              ></CancelIcon>
                            </div>
                            <div className="table-popup">
                              <div className="table-popup-div">
                                {FileData &&
                                  FileData.map((data, index) => {
                                    return (
                                      <>
                                        <h3 className="candidate-title-popup">Candidate Infomation</h3>
                                        <div className="candidate-head">
                                      
                                          <h4 className="popup_can_name">
                                            Name : {data.can_name}
                                          </h4>
                                          <h4
                                          className="can_name_table"
                                          >
                                            {data.file_from_date === null
                                              ? `Visa Expiry Date : Data Not Found`
                                              : null}
                                            {data.file_from_date &&
                                              `Visa Expiry Date: ${formatDate(
                                                data.file_from_date
                                              )}`}
                                          </h4>
                                          <h4>
                                            {data.file_expiry_date === null
                                              ? `File Expiry Date : Data Not Found`
                                              : null}
                                            {data.file_expiry_date &&
                                              `File Expiry Date : ${formatDate(
                                                data.file_expiry_date
                                              )}`}
                                          </h4>

                                          <h4>
                                            {data.visa_expiry_date === null
                                              ? `Visa Expiry Date : Data Not Found`
                                              : null}
                                            {data.visa_expiry_date &&
                                              `Visa Expiry Date: ${formatDate(
                                                data.visa_expiry_date
                                              )}`}
                                          </h4>

                                          <h4>
                                            {data.file_signed_date === null
                                              ? `MSA sigened date : Data Not Found`
                                              : null}
                                            {data.file_signed_date &&
                                              `MSA sigened date: ${formatDate(
                                                data.file_signed_date
                                              )}`}
                                          </h4>
                                        </div>
                                        <h3 className="candidate-title-popup">Candidate Remark Status</h3>
                                        <div>

                                        <h4 className="remark">
                                          {data.remarks
                                            ? `Remark :${data.remarks}`
                                            : `Remark : Data Not Found`}
                                        </h4>
                                        </div>
                                        <h3 className="candidate-title-popup">
                                          Candidate File Download</h3>
                                        {selectedButton === "i9" && (
                                          <>
                                            {FileData &&
                                            data.file_key === null ? (
                                              <div>
                                            
                                                <p className="table-err-msg">
                                                No File to Download candidate{" "}
                                                
                                                  {selectedButton} File
                                                </p>
                                              </div>
                                            ) : (
                                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                              <div className="file-download">
                                              
                                                <p
                                                  className="file-icon-text"
                                                  key={index}
                                                >
                                                  Download the
                                                  Candidate{" "}
                                                  {selectedButton.replace(
                                                    /_/g,
                                                    " "
                                                  ).toUpperCase()}{" "}
                                                  File
                                                  <span
                                                    className="download-section"
                                                    onClick={() =>
                                                      handleDownload(
                                                        FileData[0].file_key
                                                      )
                                                    }
                                                  >
                                                    Click here
                                                  </span>
                                                </p>
                                                
                                              </div>
                                            )}

                                            {/* i9Exhibit_B_Files */}
                                            {FileData &&
                                            data.exhibit_b_file_key === null ? (
                                              <>
                                                <p className="table-err-msg">
                                                  No File To Download{" "}
                                                  {selectedButton} ExhibitB
                                                  File
                                                </p>
                                               
                                              </>
                                            ) : (
                                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                              <>
                                                <p
                                                  className="file-icon-text"
                                                  key={index}
                                                >
                                               
                                                  Download the Candidate I9 ExhibitB File
                                                  <span
                                                    className="download-section"
                                                    onClick={() =>
                                                      handleDownload(
                                                        FileData[0]
                                                          .exhibit_b_file_key
                                                      )
                                                    }
                                                  >
                                                    Click here
                                                  </span>
                                                </p>

                                              </>
                                            )}
                                          </>
                                        )}
                                        {/* MSA File download */}
                                        {selectedButton === "msa" && (
                                          <>
                                            {FileData &&
                                            data.file_key === null ? (
                                              <>
                                               
                                                <p className="table-err-msg">
                                                  No File to Download candidate{" "}
                                                  {selectedButton} File
                                                </p>
                                              </>
                                            ) : (
                                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                              <>
                                               
                                                <p
                                                  className="file-icon-text"
                                                  key={index}
                                                >
                                           
                                                  Download the
                                                  Candidate{" "}
                                                  {selectedButton.replace(
                                                    /_/g,
                                                    " "
                                                  )}{" "}
                                                  File
                                                  <span
                                                    className="download-section"
                                                    onClick={() =>
                                                      handleDownload(
                                                        FileData[0].file_key
                                                      )
                                                    }
                                                  >
                                                    Click here
                                                  </span>
                                                </p>

                                              </>
                                            )}
                                            {FileData &&
                                            data.exhibit_a_file_key === null ? (
                                              <>
                                              
                                                <p className="table-err-msg">
                                                  No File to Download
                                                  Exhibit-A/PO File
                                                </p>
                                          
                                              </>
                                            ) : (
                                             
                                              <>
                                                <p
                                                  className="file-icon-text"
                                                  key={index}
                                                >
                                                 Download the
                                                  Candidate Exhibit-A/PO File
                                                  <span
                                                    className="download-section"
                                                    onClick={() =>
                                                      handleDownload(
                                                        FileData[0]
                                                          .exhibit_a_file_key
                                                      )
                                                    }
                                                  >
                                                    Click here
                                                  </span>
                                                </p>
                                               
                                              </>
                                            )}
                                          </>
                                        )}

                                        {/* W9 File Download */}

                                        {selectedButton === "w9" && (
                                          <>
                                            {FileData &&
                                            data.file_key === null ? (
                                              <>
                                                <p className="table-err-msg">
                                                  No File to Download candidate{" "}
                                                  {selectedButton} File
                                                </p>
                                           
                                              </>
                                            ) : (
                                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                              <>
                                           
                                                <p
                                                  className="file-icon-text"
                                                  key={index}
                                                >
                                                  
                                                  Download the
                                                  Candidate{" "}
                                                  {selectedButton.replace(
                                                    /_/g,
                                                    " "
                                                  )}{" "}
                                                  File
                                                  <span
                                                    onClick={() =>
                                                      handleDownload(
                                                        FileData[0].file_key
                                                      )
                                                    }
                                                  >
                                                    Click here
                                                  </span>
                                                </p>
                                              
                                              </>
                                            )}
                                            {FileData &&
                                            data.vc_file_key === null ? (
                                              <>
                                                <p className="table-err-msg">
                                                  No File to Download VOID Check
                                                  File
                                                </p>
                              
                                              </>
                                            ) : (
                                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                              <>
                                                <p
                                                  className="file-icon-text"
                                                  key={index}
                                                >
                                               
                                                  Download the
                                                  Candidate VOID Check File
                                                  <span
                                                    className="download-section"
                                                    onClick={() =>
                                                      handleDownload(
                                                        FileData[0].vc_file_key
                                                      )
                                                    }
                                                  >
                                                    Click here
                                                  </span>
                                                </p>
                                              
                                              </>
                                            )}
                                            {FileData &&
                                            data.ach_file_key === null ? (
                                              <>
                                                <p className="table-err-msg">
                                                  No File to Download ACH-Upload
                                                  File
                                                </p>
                                               
                                              </>
                                            ) : (
                                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                              <>
                                                <p
                                                  className="file-icon-text"
                                                  key={index}
                                                >
                                              
                                                  Download the
                                                  Candidate ACH-Upload File
                                                  <span
                                                    onClick={() =>
                                                      handleDownload(
                                                        FileData[0].ach_file_key
                                                      )
                                                    }
                                                  >
                                                    Click here
                                                  </span>
                                                </p>
                                                
                                              </>
                                            )}
                                          </>
                                        )}
                                        {/* Visa file Download */}
                                        {selectedButton === "visa" && (
                                          <>
                                            {FileData &&
                                            data.file_key === null ? (
                                              <>
                                                <p className="table-err-msg">
                                                  No File to Download{" "}
                                                  {selectedButton} File
                                                </p>

                                               
                                              </>
                                            ) : (
                                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                              <>
                                                
                                                <p
                                                  className="file-icon-text"
                                                  key={index}
                                                >
                                                  
                                                  Download the
                                                  Candidate{" "}
                                                  {selectedButton.replace(
                                                    /_/g,
                                                    " "
                                                  )}{" "}
                                                  File
                                                  <span
                                                    onClick={() =>
                                                      handleDownload(
                                                        FileData[0].file_key
                                                      )
                                                    }
                                                  >
                                                    Click here
                                                  </span>
                                                </p>

                                    
                                              </>
                                            )}
                                          </>
                                        )}
                                        {/* Coi File Download */}
                                        {selectedButton === "coi" && (
                                          <>
                                            {FileData &&
                                            data.file_key === null ? (
                                              <>
                                                <p className="table-err-msg">
                                                  No File to Download{" "}
                                                  {selectedButton} File
                                                </p>
                                               
                                              </>
                                            ) : (
                                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                              <>
                                               
                                                <p
                                                  className="file-icon-text"
                                                  key={index}
                                                >
                                                
                                                  Download the
                                                  Candidate{" "}
                                                  {selectedButton.replace(
                                                    /_/g,
                                                    " "
                                                  )}{" "}
                                                  File
                                                  <span
                                                    onClick={() =>
                                                      handleDownload(
                                                        FileData[0].file_key
                                                      )
                                                    }
                                                  >
                                                    Click here
                                                  </span>
                                                </p>
                                              
                                              </>
                                            )}
                                            {FileData &&
                                            data.cis_file_key === null ? (
                                              <>
                                                <p className="table-err-msg">
                                                  No File to Download Contact
                                                  info Sheet file
                                                </p>
                                             
                                              </>
                                            ) : (
                                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                              <>
                                                <p
                                                  className="file-icon-text"
                                                  key={index}
                                                >
                                                
                                                  Download the
                                                Contact info Sheet
                                                  file
                                                  <span
                                                    onClick={() =>
                                                      handleDownload(
                                                        FileData[0].cis_file_key
                                                      )
                                                    }
                                                  >
                                                    Click here
                                                  </span>
                                                </p>
                                              
                                              </>
                                            )}
                                          </>
                                        )}
                                        {/* Countersign File Download */}
                                        {selectedButton === "counter_sign" && (
                                          <>
                                            {FileData &&
                                            data.file_key === null ? (
                                              <>
                                                <p className="table-err-msg">
                                                  No File to Download Counter
                                                  Signed File
                                                </p>
                                               
                                              </>
                                            ) : (
                                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                              <>
                                                
                                                <p
                                                  className="file-icon-text"
                                                  key={index}
                                                >
                                          
                                                  Download the
                                                  Candidate{" "}
                                                  {selectedButton
                                                  .replace(
                                                    /_/g,
                                                    " "
                                                  )
                                                  }{" "}
                                                  File
                                                  <span
                                                    onClick={() =>
                                                      handleDownload(
                                                        FileData[0].file_key
                                                      )
                                                    }
                                                  >
                                                 Click here
                                                  </span>
                                                </p>
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {DeletePopupopen ? (
                      <div className="delete-popup-container">
                        <div className="delete-popup-outer">
                          <div className="delete-popup-heading">
                            <CancelIcon
                              className="close-icon"
                              onClick={handleDeletePopupClose}
                            ></CancelIcon>
                          </div>
                          <div className="delete-popup">
                            <div className="delete-popup-div">
                              <div className="close-div">
                                <img
                                  src={Trashman}
                                  alt="sry-no-img"
                                  className="close-img"
                                />
                              </div>
                              <h1>Are you sure ?</h1>
                              <p> Do you want to Delete This File</p>
                              <button
                                onClick={handleDeletePopupClose}
                                className="disagree"
                              >
                                Cancel
                              </button>
                              <button
                                onClick={() => handleDelete(deleteCandidateId)}
                                className="agree"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="pagination">
                    {DataError ? null : (
                      <ReactPaginate
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={(selected) =>
                          setCurrentPage(selected.selected)
                        }
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
              <ToastSnackbar {...snackbarprops} />
              <Footerfile />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default FileList;