import React from "react";
import Login from "./Component/Login/Login";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import Dashboard from "./Component/Dashboard/Dashboard";
import Staticfunction from "./Component/Static/Staticfunction";
import Errorpage from "./Component/404-Error-Page/Errorpage";
import CanticateDetails from "./Component/Candicate-Details-Form/Canticate";
import FileList from "./Component/Dashboard/FileList";
import "./Component/AxiosConfig";
function App() {
  const {
    logout,
    setlogout,
    otppopup,
    setotppopup,
    email,
    setemail,
    setmessages,
    messages,
    openToast,
    setOpenToast,
    severityNote,
    setSeverityNote,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    openStack,
    setOpenStack,
    snackBarTimeOut,
    Edit,
    setEdit,
    EditData,
    setEditData,
    CanId,
    SetCanId,
    isLoading,
    setIsLoading,
    TokenExpired,
    setTokenExpired,
  } = Staticfunction();
  const dynamicprops = {
    logout,
    setlogout,
    otppopup,
    setotppopup,
    email,
    setemail,
    setmessages,
    messages,
    openToast,
    setOpenToast,
    severityNote,
    setSeverityNote,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    openStack,
    setOpenStack,
    snackBarTimeOut,
    Edit,
    setEdit,
    EditData,
    setEditData,
    CanId,
    SetCanId,
    isLoading,
    setIsLoading,
    TokenExpired,
    setTokenExpired,
  };

  const PrivateRoute = () => {
    return localStorage.getItem("email") ? <Outlet /> : <Navigate to="/" />;
  };

  const AuthRoute = () => {
    return localStorage.getItem("email", email) ? (
      <Navigate to="/dashboard" replace />
    ) : (
      <Outlet />
    );
  };

  return (
    <>
      <Router>
        <Routes>
          <Route element={<AuthRoute />}>
            <Route path="/" element={<Login {...dynamicprops} />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="*" element={<Errorpage />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path="/dashboard"
              element={<Dashboard {...dynamicprops} />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path="/candidate-filelist"
              element={<FileList {...dynamicprops} />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path="/candidate-details"
              element={<CanticateDetails {...dynamicprops} />}
            />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
