import axios from "axios";
const config = {
  apiEndpoint: "https://driveapi.vyhire.com/api",
  featureFlags: {
    enableAnalytics: false,
    enableNotifications: true,
  },
};
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default config;
