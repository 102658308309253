let config;
console.log(process.env.REACT_APP_ENV);
if (process.env.REACT_APP_ENV === "development") {
  config = require("./config/development").default;
} else if (process.env.REACT_APP_ENV === "demo") {
  config = require("./config/demo").default;
} else {
  config = require("./config/production").default;
}

export default config;
