import AWS from "aws-sdk";
import * as FileUrl from "./file_url_helper";

AWS.config.update({
  accessKeyId: FileUrl.AWS_ACCESS_KEY_ID,
  secretAccessKey: FileUrl.AWS_SECRET_ACCESS_KEY,
  region: FileUrl.AWS_S3_BUCKET_REGION,
});
const s3 = new AWS.S3();

async function downloadFileFromS3(keys) {
  try {
    const promises = keys.map(async (key) => {
      const params = {
        Bucket: FileUrl.AWS_S3_BUCKET_NAME,
        Key: key,
        Expires: 56600,
      };

      const downloadLink = await s3.getSignedUrlPromise("getObject", params);
      return downloadLink;
    });

    const downloadLinks = await Promise.all(promises);
    return downloadLinks;
  } catch (error) {
    console.error("Failed to generate download links:", error);
    return [];
  }
}

export default downloadFileFromS3;
