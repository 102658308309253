import React from "react";
import {

  Stack,
  Snackbar as SnackbarMUI,
  Alert as MuiAlert,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledSnackbarMUI = styled(SnackbarMUI)`
  .cls-alert-txt {
    font-size: 14px !important;
    box-shadow: 2px 2px 1px #000000bf;
  }
  .cls-alert-txt,
  svg {
    color: #fff !important;
  }
  .cls-alert-txt.cls-warning {
    background: #e2b93b;
  }
  .cls-alert-txt.cls-info {
    background: #007fff;
  }
  .cls-alert-txt.cls-error {
    background: #eb5757;
  }
  .cls-alert-txt.cls-success {
    background: #10bc55;
  }
`;

function ToastSnackbar(props) {
  const {
    openToast=false,
    severityNote ="info",
    messages,
 
    vertical ="top",
    horizontal="right",
    setOpenToast,
  } = props || {};
  const CloseSnackBar = () => {
    setOpenToast(false)
  }
 return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <StyledSnackbarMUI
      autoHideDuration={2000}
        open={openToast}
        anchorOrigin={{ vertical, horizontal }}
      >
        <MuiAlert
          className={`cls-alert-txt cls-${severityNote}`}
          onClose={CloseSnackBar}
          severity={severityNote}
          sx={{ width: "100%" }}
        >
          {messages}
        </MuiAlert>
      </StyledSnackbarMUI>
    </Stack>
  );
}

export { ToastSnackbar };
export default ToastSnackbar;

