import React from "react";

function Staticfunction() {
  const [logout, setlogout] = React.useState();
  const [otppopup, setotppopup] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [email, setemail] = React.useState();
  const [messages, setmessages] = React.useState("");
  const [openToast, setOpenToast] = React.useState(false);
  const [severityNote, setSeverityNote] = React.useState("info");
  const [vertical, setVertical] = React.useState("bottom");
  const [horizontal, setHorizontal] = React.useState("right");
  const [openStack, setOpenStack] = React.useState(false);
  const [Edit, setEdit] = React.useState(false);
  const [EditData, setEditData] = React.useState();
  const [CanId, SetCanId] = React.useState();
  const [TokenExpired, setTokenExpired] = React.useState(false);

  const snackBarTimeOut = () => {
    setTimeout(() => setOpenToast(false), 3000);
  };

  return {
    logout,
    setlogout,
    otppopup,
    setotppopup,
    email,
    setemail,
    messages,
    setmessages,
    openToast,
    setOpenToast,
    severityNote,
    setSeverityNote,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    openStack,
    setOpenStack,
    snackBarTimeOut,
    Edit,
    setEdit,
    EditData,
    setEditData,
    CanId,
    SetCanId,
    isLoading,
    setIsLoading,
    TokenExpired,
    setTokenExpired,
  };
}

export default Staticfunction;
