// Candidate-form-Upload
export const CANDIDATE_FORM_UPLOAD = "/create-data";
export const CANDIDATE_EDIT_FORM_UPLOAD = "/update-data";
// Candidate-form-list
export const CANDIDATE_TABLE_LIST = "/data";
export const CANDIDATE_FILTER_VALUES = "/data";
export const CANDIDATE_FILE_DETAILS = "/data-by-type";
export const CANDIDATE_DATA_DELETE = "/delete-data";
export const CANDIDATE_DATA_EDIT = "/update-data-view";
// Login-Page
export const OTP_GENERATE = "/otp-generate";
export const OTP_VERIFICATION = "/otp-verify";
//Dashboard-page
export const GET_COUNT_BY_DATA = "/data-by-type-count";
export const GET_EXPIRY_DATE_COUNT = "/expiry-days-count";
