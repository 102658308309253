import React from "react";
import ExpiryLogo from "../image/logo-error.svg";
import "../Expiry-Login-Screen/Expiry-login.css";
const clearsession = () => {
  localStorage.clear("email");
};
function ExpiryScreen() {
  return (
    <div className="expiry-login-container">
      <img src={ExpiryLogo} alt="no img" />
      <h1>"Your session is expired. Please login again"</h1>
      <a href="/" onClick={() => clearsession()}>
        <button className="re-login"> Login Again</button>
      </a>
    </div>
  );
}

export default ExpiryScreen;
